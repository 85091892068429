@import'bootstrap/dist/css/bootstrap.min.css';

html,body {
  background-color: #000;
  height: 100%;
  width: 100%;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-gap: 10px 10px;
  grid-auto-rows: minMax(100px, auto);
  padding: 10px;
}

.Landing {
  grid-column: 1/14;
  grid-row: 1;
}
.Contact{
grid-column: 1/14;
grid-row: 2;
}